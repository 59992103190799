<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo" :to="{path:'/'}">
      <cost-logo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Page Not Found 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton } from 'bootstrap-vue'
import CostLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    CostLogo,
    BLink,
    BButton,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
